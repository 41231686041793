import { useDispatch, useSelector } from 'react-redux';

import { TradeDirectionEnum } from '@/__codegen__/graphql';
import { editShipmentListView } from '@/shared/redux/shipments/actions';
import { AppState } from '@/shared/redux/store';
import { ActiveView } from '@/shared/types/shipment';

import { VIEW_FILTER_OPTIONS } from '../constants/shipments';

export const useShipmentListActiveView = (
  tradeDirection: TradeDirectionEnum, teamId?: string | null,
) => {
  const defaultView = VIEW_FILTER_OPTIONS[tradeDirection][0];
  const dispatch = useDispatch();
  // Default to first available option if there is no redux state (and not updating redux)
  // to allow elements like Shipment list to load immediately.
  const activeView = useSelector((state: AppState) => {
    if (!teamId) return defaultView;

    // If team id and trade direction given
    if (state.shipments.view?.[teamId]?.[tradeDirection]) {
      return state.shipments.view?.[teamId]?.[tradeDirection];
    }

    // If no team id value is saved, then just grab the first view for the given trade direction
    if (VIEW_FILTER_OPTIONS?.[tradeDirection]?.[0]) {
      return VIEW_FILTER_OPTIONS[tradeDirection][0];
    }

    // Otherwise just return 'default'
    return defaultView;
  });

  const updateActiveView = (view: ActiveView, teamIdArg: string) => {
    const isValidView = VIEW_FILTER_OPTIONS[tradeDirection].some((option) => option === view);
    if (isValidView) {
      dispatch(editShipmentListView(teamIdArg, tradeDirection, view));
    }
  };

  return [activeView, updateActiveView] as const;
};
