/* eslint-disable no-underscore-dangle */
import {
  SHIPMENT_LIST_FILTERS,
  SHIPMENT_LIST_VIEWS,
  DEFAULT_SHIPMENT_RICH_FILTERS,
} from '../../utils/constants/shipments';

// TODO: tsrf: fix any state
const cascadeForViewFilter = (state: any, _customerTeamId: string) => {
  const tradeDirection = state.activeTradeDirection;
  const view = state.view?.[_customerTeamId]?.[tradeDirection];

  // When view filter for import is changed to default, please update booking status
  // and delivery place to null
  if (tradeDirection === 'import' && view === SHIPMENT_LIST_VIEWS.default) {
    const newFilters = {
      ...state.filters,
      [_customerTeamId]: {
        ...state.filters?.[_customerTeamId],
        [tradeDirection]: {
          ...state.filters?.[_customerTeamId]?.[tradeDirection],
          bookingStatus: DEFAULT_SHIPMENT_RICH_FILTERS.bookingStatus,
          deliveryAddressId: DEFAULT_SHIPMENT_RICH_FILTERS.deliveryAddressId,
        },
      },
    };

    localStorage.setItem(SHIPMENT_LIST_FILTERS, JSON.stringify(newFilters));

    return {
      ...state,
      filters: newFilters,
    };
  }

  return state;
};

export {
  cascadeForViewFilter,
};
